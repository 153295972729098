import {
  Box,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  Link,
  Spacer,
} from "@chakra-ui/react";
import React from "react";
import { FaFacebookF, FaYelp } from "react-icons/fa";

const Navbar = () => {
  return (
    <Box bg="orange.500" w="100%" p={4} color="white">
      <Flex>
        <Box p="2">{/* Placeholder for left side content */}</Box>
        <Spacer />
        <Box p="2" display="flex" alignItems="center">
          <Image src="/logo.png" alt="Logo" boxSize="75px" mr={2} />
          <Heading size="xl">Trupti's Academy</Heading>
        </Box>
        <Spacer />
        <Box p="2" display="flex" alignItems="center" columnGap={2}>
          <Link href="https://www.facebook.com/people/Truptis-Academy/61558115399148/" isExternal>
            <IconButton
              as={Icon}
              icon={<FaFacebookF />}
              color="white"
              bg="transparent"
              _hover={{ bg: "transparent" }}
            />
          </Link>
          <Link href="https://www.yelp.com/biz/truptis-hindi-academy-antioch-2" isExternal>
            <IconButton
              as={Icon}
              icon={<FaYelp />}
              color="white"
              bg="transparent"
              _hover={{ bg: "transparent" }}
            />
          </Link>
        </Box>
      </Flex>
    </Box>
  );
};

export default Navbar;
