import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";

const InstructorBlurb = () => {
  return (
    <Flex align="center" borderWidth="1px" borderRadius="lg" p={5}>
      <Image
        borderRadius="full"
        boxSize="150px"
        src="/trupti.png"
        alt="Instructor Image"
        mr={4}
      />
      <Box>
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          About Trupti
        </Text>
        <Text fontSize="md" mb={4}>
          Trupti is a former Hindi teacher and native Hindi speaker. She has
          prior experience teaching Hindi to young students and loves to share
          her language and culture with her students.
        </Text>
        <Text fontSize="md" mb={4}>
          With Trupti, you can be assured that you will get a teacher who is
          devoted to helping you master the language. She is patient and adapts
          her teaching style to a student's needs.
        </Text>
        <Text fontSize="md">
          To expand her reach, she is now offering private Hindi lessons online
          or in person,{" "}
          <Box as="span" fontWeight="bold">
            free for a limited time.
          </Box>
        </Text>
      </Box>
    </Flex>
  );
};

export default InstructorBlurb;
