import { Box } from "@chakra-ui/react";
import React from "react";
import Calendly from "../components/Calendly";
import Footer from "../components/Footer";
import InstructorBlurb from "../components/InstructorBlurb";
import Navbar from "../components/Navbar";

const Home = () => {
  return (
    <Box>
      <Navbar />
      <Box p={10} minHeight="90vh">
        <InstructorBlurb />
        <Calendly />
      </Box>
      <Footer />
    </Box>
  );
};

export default Home;
