import { Box, Link, Text } from "@chakra-ui/react";
import React from "react";

const Footer = () => {
  return (
    <Box bg="orange.500" w="100%" p={4} color="white" mt={5}>
      <Text fontSize="md" textAlign="center">
        For inquiries, email us at{" "}
        <Link href="mailto:contact-us@trupti-academy.org" color="teal.200">
          contact-us@trupti-academy.org
        </Link>
      </Text>
    </Box>
  );
};

export default Footer;
