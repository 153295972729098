import { Box } from "@chakra-ui/react";
import React from "react";
import { Helmet } from "react-helmet";

const Calendly = ({ type }) => {
  console.log(type);
  return (
    <Box>
      <Helmet>
        <script
          src="https://assets.calendly.com/assets/external/widget.js"
          type="text/javascript"
          async
        ></script>
      </Helmet>
      <div
        data-url="https://calendly.com/admin-mfd4"
        style={{ minWidth: "320px", height: "750px" }}
        className="calendly-inline-widget"
      ></div>
    </Box>
  );
};

export default Calendly;
